import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "../styles/scss/header.scss";
import "../styles/scss/hamburger.scss";

const Header = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      site {
        siteMetadata {
          nav {
            name
            link
          }
          social {
            name
            icon
            url
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const nav = data.site.siteMetadata?.nav  
  const social = data.site.siteMetadata?.social

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark static-top">
        <div className="container">
          <a className="navbar-brand" href="/#">
            <img src="./ablogo-white.svg" alt="Adrian Bright"></img>
          </a>
          <div className="hamburger" data-bs-toggle="collapse" data-bs-target="#mobile-nav" aria-controls="mobile-nav" aria-expanded="false" aria-label="Toggle navigation">
            <label>
              <input type="checkbox"/>
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
                <path className="line--2" d="M0 50h70" />
                <path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
              </svg>
            </label>
          </div>
          <div className="navbar-collapse collapse justify-content-between" id="mobile-nav">
          <ul className="navbar-nav mr-auto">
            {nav.map(link => (
              <li className="nav-item" key={link.name}>
                <Link className="nav-link" to={'/'+ link.link}> 
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
          <ul className="header-social navbar-nav mr-auto justify-content-center"> 
            {social.map(link => (
              <li className="nav-item" key={link.name}>
                <OutboundLink className="nav-link" target="_blank" href={link.url}>
                  <img src={link.icon} alt={link.name} className="social-icon"></img> 
                  </OutboundLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}
Header.defaultProps = {
  siteTitle: ``,
}

export default Header
